import React from 'react'
import { toast as originalToast, ToastOptions } from 'react-toastify'

type ToastHandler = (msg?: string | React.ReactElement | null, options?: ToastOptions) => void
const success: ToastHandler = (msg, options = {}) => {
  const autoClose = options.autoClose ?? 5000
  originalToast.success(msg, { ...options, autoClose })
}

const error: ToastHandler = (msg, options = {}) => {
  const autoClose = options.autoClose ?? 15000
  originalToast.error(msg, { ...options, autoClose })
}

const info: ToastHandler = (msg, options = {}) => {
  const autoClose = options.autoClose ?? 15000
  originalToast.info(msg, { ...options, autoClose })
}

const warning: ToastHandler = (msg, options = {}) => {
  const autoClose = options.autoClose ?? 15000
  originalToast.warning(msg, { ...options, autoClose })
}

export const toast = {
  ...originalToast,
  success,
  error,
  info,
  warning,
}
