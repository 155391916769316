import React from 'react'
import cx from 'clsx'
import { ToastContainer, TypeOptions } from 'react-toastify'

import './toast.css'
import 'react-toastify/dist/ReactToastify.css'

const Toast = () => {
  const getIconStyle = (type: TypeOptions) => {
    switch (type) {
      case 'success':
        return 'bg-emerald-600'
      case 'error':
        return 'bg-red-600'
      case 'info':
        return 'bg-neutral-700'
      case 'warning':
        return 'bg-amber-600'
      default:
        return 'bg-emerald-600'
    }
  }

  return (
    <ToastContainer
      hideProgressBar
      newestOnTop
      closeOnClick
      pauseOnFocusLoss
      draggable
      pauseOnHover
      closeButton={false}
      icon={({ type }) => (
        <div
          className={cx(
            'text-white rounded-lg px-2 py-0.5 h-6 font-medium leading-5 hover:opacity-90 capitalize',
            getIconStyle(type),
          )}
        >
          {type}
        </div>
      )}
    />
  )
}

export default Toast
