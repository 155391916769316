import { StateCreator } from 'zustand'
import { produce } from 'immer'
import { OrganizationIntegration } from '@models/Organization'

export interface OrganizationState {
  organization: number | undefined
  organizationName: string | undefined
  integration: OrganizationIntegration | undefined
}

export interface OrganizationActions {
  setOrganization: (step: number) => void
  setOrganizationIntegration: (integration?: OrganizationIntegration) => void
  switchOrganization: (organizationId: number, organizationName: string, integration?: OrganizationIntegration) => void
}

export type OrganizationSlice = OrganizationState & OrganizationActions

export const initialOrganizationState: OrganizationState = {
  organization: undefined,
  organizationName: undefined,
  integration: undefined,
}

export const createOrganizationSlice: StateCreator<OrganizationSlice, [], [], OrganizationSlice> = (set) => ({
  organization: undefined,
  organizationName: undefined,
  integration: undefined,
  setOrganization: (organization) =>
    set(
      produce((state: OrganizationSlice) => {
        state.organization = organization
      }),
    ),
  setOrganizationIntegration: (integration) => {
    set(
      produce((state: OrganizationSlice) => {
        state.integration = integration
      }),
    )
  },
  switchOrganization: (organizationId, organizationName, integration?) => {
    set(
      produce((state: OrganizationSlice) => {
        state.organization = organizationId
        state.organizationName = organizationName
        state.integration = integration
      }),
    )
  },
})
