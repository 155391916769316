import React, { useMemo } from 'react'
import Select, { GroupBase, InputActionMeta, Props, SelectInstance, components } from 'react-select'
import Creatable from 'react-select/creatable'
import cx from 'clsx'
import Tooltip from '@src/components/Tooltip'
import { twMerge } from 'tailwind-merge'

import { QuestionMarkCircleIcon } from '@heroicons/react/outline'
import { IconChevronDown, IconX } from '@tabler/icons-react'
import { getSelectStyles } from './utils'

export { useSelectHelpers } from './hooks/useSelectHelpers'

interface ReactSelectProps {
  label?: string | React.ReactElement
  errorMessage?: string
  tooltip?: string
  required?: boolean
  refObj?: React.RefObject<SelectInstance>
  classes?: {
    control?: string
    label?: string
    container?: string
    errorMessage?: string
  }
  isClearable?: boolean
  onCreateOption?: (value: string) => void
  handleInputChange?: (newValue: string, actionMeta: InputActionMeta) => void
}

export type Option = {
  label: string
  value: string
  [key: string]: unknown
}

export const ReactSelect = <
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  className,
  label,
  tooltip,
  errorMessage,
  required,
  classes,
  onCreateOption,
  handleInputChange,
  isClearable,
  components: selectComponents,
  ...props
}: Props<Option, IsMulti, Group> & ReactSelectProps) => {
  const styles = useMemo(() => getSelectStyles<Option, IsMulti, Group>(errorMessage), [errorMessage])

  const tooltipId = `${props.name || props.id || 'select'}-${label}`
  const labelElement = label && (
    <label className={cx('flex items-center mb-1.5 overflow-auto', classes?.label)}>
      {tooltip ? (
        <>
          <div data-tooltip-id={tooltipId} className="flex items-center">
            {label}
            <QuestionMarkCircleIcon className="h-3 ml-1" />
          </div>
          <Tooltip id={tooltipId}>
            <span>{tooltip}</span>
          </Tooltip>
        </>
      ) : (
        label
      )}
      {required && <span className="text-red-500">*</span>}
    </label>
  )

  const Cmp = onCreateOption ? Creatable : Select

  return (
    <div className={twMerge(cx(!errorMessage && 'mb-[1rem]', className))} onClick={(e) => e.stopPropagation()}>
      <div className={cx(classes?.container)}>
        {labelElement}
        <Cmp<Option, IsMulti, Group>
          menuPortalTarget={document.body}
          components={{
            ...selectComponents,
            Control: ({ children, ...propsControl }) => (
              <components.Control
                {...propsControl}
                innerProps={
                  {
                    'data-qa': `${label || props.name || props.id}-select`,
                    ...propsControl.innerProps,
                  } as Record<string, unknown>
                }
              >
                {children}
              </components.Control>
            ),
            Option:
              selectComponents?.Option ||
              ((props) => {
                return (
                  <components.Option
                    {...props}
                    innerProps={
                      { 'data-qa': `${props.label}-option`, ...props.innerProps } as React.DetailedHTMLProps<
                        React.HTMLAttributes<HTMLDivElement>,
                        HTMLDivElement
                      >
                    }
                  />
                )
              }),
            ClearIndicator: (props) => {
              return (
                components.ClearIndicator && (
                  <components.ClearIndicator {...props}>
                    <IconX className="text-neutral-300" stroke={1.5} size={16} />
                  </components.ClearIndicator>
                )
              )
            },
            DropdownIndicator: (props) => {
              return (
                components.DropdownIndicator && (
                  <components.DropdownIndicator {...props}>
                    <IconChevronDown className="text-neutral-300" stroke={1.5} size={16} />
                  </components.DropdownIndicator>
                )
              )
            },
          }}
          menuPlacement="auto"
          {...props}
          className={cx('rounded-base')}
          classNames={{
            control: (propsControl) =>
              cx(classes?.control as string, propsControl.isDisabled ? '!bg-netural-50 !border-gray-300' : ''),
          }}
          onCreateOption={onCreateOption}
          onInputChange={handleInputChange}
          isClearable={isClearable}
          styles={{ ...styles, ...(props.styles || {}) }}
        />
      </div>
      {errorMessage && (
        <div className={cx('w-full mt-0.5 text-[11px] text-red-500', classes?.errorMessage)}>{errorMessage}</div>
      )}
    </div>
  )
}

export default ReactSelect
