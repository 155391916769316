import {
  useQuery,
  useMutation,
  QueryKey,
  UseQueryOptions,
  UseMutationOptions,
  MutationFunction,
  QueryFunction,
} from '@tanstack/react-query'
import { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios'

import api from '../api'

type URL<TRequest> = ((data?: TRequest) => string) | string
type UrlParams = Record<string, string | undefined>
export type BodyData<TRequest> = { body?: TRequest; urlParams?: UrlParams }
export type RequestData<TRequest> = TRequest | BodyData<TRequest>

const getData = <TRequest>(
  data: RequestData<TRequest>,
): {
  body?: TRequest
  urlParams?: UrlParams
} => {
  if (!!(data as BodyData<TRequest>).body || !!(data as BodyData<TRequest>).urlParams) {
    const dataRaw = data as BodyData<TRequest>
    return {
      body: dataRaw.body,
      urlParams: dataRaw.urlParams,
    }
  }

  return {
    body: data as TRequest,
  }
}

function getPath<TRequest>(url: URL<TRequest>, data?: TRequest, urlParams?: Record<string, string | undefined>) {
  let path = typeof url === 'string' ? url : url(data)
  if (urlParams) {
    Object.keys(urlParams)
      .filter((key) => Boolean(urlParams[key]))
      .forEach((key) => {
        path = path.replace(`:${key}`, urlParams[key] as string)
      })
  }
  return path
}

interface Params<TRes, TReq = unknown, TErr = unknown> {
  axios?: AxiosRequestConfig
  query?: UseQueryOptions<TRes, AxiosError<TErr>, TRes>
  mutation?: Omit<UseMutationOptions<TRes, AxiosError<TErr>, TReq>, 'mutationFn'>
  function?: MutationFunction<TRes, TReq> | QueryFunction<TRes>
}

export const useGetOne = <TResponse = unknown, TError = unknown>(
  key: QueryKey,
  url: string,
  params?: Params<TResponse, unknown, TError>,
) => {
  const service: QueryFunction<TResponse> = async (): Promise<TResponse> => {
    const { data }: AxiosResponse<TResponse> = await api.get(url, params?.axios)
    return data
  }
  return useQuery<TResponse, AxiosError<TError>>({
    ...params?.query,
    queryKey: key,
    queryFn: (params?.function as QueryFunction<TResponse>) || service,
  })
}

export const useGetList = <TResponse = unknown>(key: QueryKey, url: string, params?: Params<TResponse[]>) => {
  const service: QueryFunction<TResponse[]> = async (): Promise<TResponse[]> => {
    const { data }: AxiosResponse<TResponse[]> = await api.get(`${url}`, params?.axios)
    return data
  }
  return useQuery<TResponse[], AxiosError>({ ...params?.query, queryKey: key, queryFn: service })
}

export const useCreate = <TRequest = unknown, TResponse = unknown, TError = unknown>(
  url: URL<TRequest>,
  params?: Params<TResponse, RequestData<TRequest>, TError>,
) => {
  const service: MutationFunction<TResponse, RequestData<TRequest>> = async (data): Promise<TResponse> => {
    const requestData = getData(data)
    const res: AxiosResponse<TResponse> = await api.post(
      `${getPath(url, requestData.body, requestData.urlParams)}`,
      requestData.body,
      params?.axios,
    )

    return res.data
  }

  return useMutation<TResponse, AxiosError<TError>, RequestData<TRequest>>(
    (params?.function as MutationFunction<TResponse, RequestData<TRequest>>) || service,
    params?.mutation,
  )
}

export const useUpdate = <TRequest, TResponse = unknown, TError = AxiosError>(
  url: URL<TRequest>,
  params?: Params<TResponse, RequestData<TRequest>>,
) => {
  const service: MutationFunction<TResponse, RequestData<TRequest>> = async (
    data: RequestData<TRequest>,
  ): Promise<TResponse> => {
    const requestData = getData(data)
    const res: AxiosResponse<TResponse> = await api.put(
      `${getPath(url, requestData.body, requestData.urlParams)}`,
      requestData.body,
      params?.axios,
    )

    return res.data
  }

  return useMutation<TResponse, AxiosError<TError>, RequestData<TRequest>>(service, params?.mutation)
}

export const usePatch = <TRequest, TResponse = unknown, TError = AxiosError>(
  url: URL<TRequest>,
  params?: Params<TResponse, RequestData<TRequest>>,
) => {
  const service: MutationFunction<TResponse, RequestData<TRequest>> = async (data): Promise<TResponse> => {
    const requestData = getData(data)
    const res: AxiosResponse<TResponse> = await api.patch(
      `${getPath(url, requestData.body, requestData.urlParams)}`,
      requestData.body || undefined,
      params?.axios,
    )

    return res.data
  }

  return useMutation<TResponse, AxiosError<TError>, RequestData<TRequest>>(service, params?.mutation)
}

export const useDelete = <TRequest, TResponse = null>(
  url: URL<TRequest>,
  params?: Params<TResponse, RequestData<TRequest>>,
) => {
  const service: MutationFunction<TResponse, RequestData<TRequest>> = async (
    data?: RequestData<TRequest>,
  ): Promise<TResponse> => {
    const requestData = getData(data)
    const res: AxiosResponse<TResponse> = await api.delete(`${getPath(url, requestData.body, requestData.urlParams)}`, {
      ...params?.axios,
      data: requestData.body,
    })

    return res.data
  }

  return useMutation<TResponse, AxiosError, RequestData<TRequest>>(service, params?.mutation)
}
