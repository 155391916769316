import { useMemo } from 'react'
import { MultiValue, SingleValue } from 'react-select'

type TValue<IsMulti extends boolean = false, T = string> = IsMulti extends false ? T : T[]
export const useSelectHelpers = <T = string, IsMulti extends boolean = false>(
  options: Option<T>[],
  value?: TValue<IsMulti, T>,
  onChange?:
    | ((value?: TValue<IsMulti, T>) => void)
    | React.Dispatch<React.SetStateAction<Optional<TValue<IsMulti, T>>>>,
) => {
  const handleChange = (option: SingleValue<Option<T>> | MultiValue<Option<T>>) => {
    if (onChange) {
      if (!Array.isArray(option)) {
        onChange((option as Option<T>)?.value as TValue<IsMulti, T>)
      } else {
        const val = (option as Option<T>[]).map((el) => el.value)

        onChange(val as unknown as TValue<IsMulti, T>)
      }
    }
  }
  return useMemo(() => {
    let optionValue
    if (Array.isArray(value)) {
      optionValue = options.filter((el, index, a) => {
        const isOption = value.includes(el.value)
        const isAnotherElem = options.findIndex((option) => option.value === el.value) < index
        return isOption && !isAnotherElem
      })
    } else {
      optionValue = options.find((option) => option.value === value)
    }

    return { optionValue, handleChange }
  }, [options, value])
}

export default useSelectHelpers
