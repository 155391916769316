export enum QuestionSlug {
  Software = 'software',
  CompanySize = 'company_size',
  Phone = 'phone',
  RequestType = 'request_type',
  Industry = 'industry',
  Department = 'department',
  Role = 'role',
  Location = 'location',
  Source = 'source',
}

export enum CompanySize {
  LessThan50 = '1-50',
  LessThan200 = '51-200',
  LessThan500 = '201-500',
}

export enum SoftwareIntegration {
  XERO = 'XERO',
  QuickBooks = 'QuickBooks Online',
  None = 'None',
}

export enum OnboardingStep {
  welcome = 'welcome',
  workflow = 'workflow',
  request = 'request',
  bill = 'bill',
  vendor = 'vendor',
  customer = 'customer',
  po = 'po',
  invoice = 'invoice',
  contract = 'contract',
  team = 'team',
  upgrade = 'upgrade',
}

export enum OnboardingType {
  bill = 'bill',
  po = 'po',
  invoice = 'invoice',
  vendor = 'vendor',
  contract = 'contract',
  access = 'access',
  project = 'project',
  timeOff = 'timeOff',
}
