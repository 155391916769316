import React, { RefAttributes, useMemo, useState } from 'react'
import cx from 'clsx'
import { twMerge } from 'tailwind-merge'

import Tooltip from '@src/components/Tooltip'
import { ExclamationCircleIcon, QuestionMarkCircleIcon } from '@heroicons/react/outline'
import TextareaAutosize from 'react-textarea-autosize'

import styles from './index.module.css'
import { IconEye, IconEyeOff } from '@tabler/icons-react'

export const enum Type {
  textarea = 'textarea',
  number = 'number',
  text = 'text',
  password = 'password',
}

type FormControlElement = HTMLInputElement | HTMLTextAreaElement

export interface InputProps extends React.HTMLAttributes<FormControlElement> {
  label?: string | React.ReactElement
  classes?: {
    label?: string
    control?: string
    container?: string
    controlContainer?: string
    errorMessage?: string
    errorIcon?: string
  }
  value?: string | number
  errorMessage?: string
  required?: boolean
  name?: string
  tooltip?: string
  allowEnterClick?: boolean
  isOnboard?: boolean
  type?: Type | keyof typeof Type
  isValid?: boolean
  isInvalid?: boolean
  'data-onboard'?: string
  disabled?: boolean
  rows?: number
  maxlength?: number
  autoComplete?: string
  min?: number
  max?: number
  step?: number
  maxRows?: number
  minRows?: number
  autoSize?: boolean
}

const getAdditionalProps = (props: InputProps, isOnboard?: boolean) => {
  const identifier = props.id || props.name
  const additionalProps: Record<string, unknown> = {}

  if (identifier) {
    additionalProps['data-qa'] = `${props.label || identifier}-input`
  }
  if ((identifier && isOnboard) || props['data-onboard']) {
    additionalProps['data-onboard'] = `${props['data-onboard'] || identifier}-input`
  }
  if (props.type === Type.textarea) {
    additionalProps.as = Type.textarea
  }

  return additionalProps
}

export const Input = React.forwardRef<FormControlElement, InputProps>((props, ref) => {
  const {
    label,
    errorMessage,
    type = Type.text,
    required = true,
    allowEnterClick = false,
    className,
    classes = {},
    isInvalid,
    tooltip,
    isOnboard,
    autoComplete,
    min,
    max,
    ...otherProps
  } = props

  const additionalProps = getAdditionalProps(props, isOnboard)

  const [showPassword, setShowPassword] = useState(false)
  const handleTogglePassword = () => setShowPassword(!showPassword)

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!allowEnterClick && e.key === 'Enter' && type !== Type.textarea) {
      e.preventDefault()
      e.currentTarget.blur()
    }
  }

  const tooltipId = `${props.name || props.id || 'input'}-${label}`

  const labelElement = label && (
    <label className={cx('flex items-center text-gray-700 mb-0.5 break-words', classes?.label)}>
      {tooltip ? (
        <>
          <span data-tooltip-id={tooltipId} className="flex items-center">
            {label}
            <QuestionMarkCircleIcon className="h-3 ml-1" />
          </span>
          <Tooltip id={tooltipId}>
            <span>{tooltip}</span>
          </Tooltip>
        </>
      ) : (
        label
      )}
      {required && <span className="text-red-500">*</span>}
    </label>
  )

  const inputType = type === Type.password ? (showPassword ? Type.text : Type.password) : type
  const isFieldInvalid = isInvalid || !!errorMessage

  const inputProps = {
    className: twMerge(
      cx(
        styles.formControl,
        'px-4 py-[5px]',
        classes?.control,
        isFieldInvalid && styles.invalid,
        props.disabled && 'bg-[#f2f2f2]',
        props.errorMessage && 'pr-9',
      ),
    ),
    required,
    type: inputType,
    ref,
    onKeyUp: handleKeyUp,
    autoComplete,
    min,
    max,
    ...otherProps,
    ...additionalProps,
  }
  const element = useMemo(() => {
    if (type !== Type.textarea) {
      return React.createElement('input', inputProps)
    }

    if (inputProps.autoSize) {
      return React.createElement(TextareaAutosize, inputProps as RefAttributes<HTMLTextAreaElement>)
    } else {
      return React.createElement('textarea', inputProps)
    }
  }, [type, inputProps])

  return (
    <div className={twMerge(cx('relative', !errorMessage && 'mb-[1rem]', className))}>
      <div className={cx(classes.container)}>
        {labelElement}
        <div className={cx('relative pointer-events-auto group', classes.controlContainer)}>
          {element}
          {isFieldInvalid && (
            <ExclamationCircleIcon
              className={cx(
                'absolute right-4 top-2 h-4 w-4 stroke-red-500',
                errorMessage && 'opacity-100 group-hover:opacity-0',
                classes.errorIcon,
              )}
            />
          )}
          {type === Type.password && (
            <button
              type="button"
              className={cx('absolute right-3.5 top-1.5', errorMessage && 'opacity-0 group-hover:opacity-100')}
              onClick={handleTogglePassword}
            >
              {showPassword ? (
                <IconEye className="h-5 w-5 opacity-60 hover:opacity-100" />
              ) : (
                <IconEyeOff className="h-5 w-5 opacity-60 hover:opacity-100" />
              )}
            </button>
          )}
        </div>
      </div>
      {errorMessage && (
        <div className={cx('w-full mt-0.5 text-[11px] text-red-500', classes.errorMessage)}>{errorMessage}</div>
      )}
    </div>
  )
})

export default Input
