import { StateCreator } from 'zustand'
import { produce } from 'immer'

import { OnboardingStep, OnboardingType } from '@type/onboarding'

export interface OnboardingState {
  onboardingStep: OnboardingStep | null
  onboardingType: OnboardingType
  startPath: string | null
  isOnboarding: boolean
  approvalRequestData: Record<string, unknown> | null
}

export interface OnboardingActions {
  setOnboardingStep: (step: OnboardingStep | null) => void
  setIsOnboarding: (isOnboarding: boolean) => void
  setOnboardingType: (onboardingType: OnboardingType) => void
  setStartPath: (path: string | null) => void
  setApprovalRequestData: (data: Record<string, unknown> | null) => void
  reset: () => void
}

export type OnboardingSlice = OnboardingState & OnboardingActions

export const initialOnboardingState: OnboardingState = {
  onboardingStep: null,
  onboardingType: OnboardingType.project,
  startPath: null,
  isOnboarding: false,
  approvalRequestData: null,
}

export const createOnboardingSlice: StateCreator<OnboardingSlice, [], [], OnboardingSlice> = (set) => ({
  ...initialOnboardingState,
  setIsOnboarding: (isOnboarding) =>
    set(
      produce((state: OnboardingSlice) => {
        state.isOnboarding = isOnboarding
      }),
    ),
  setOnboardingType: (onboardingType = OnboardingType.project) =>
    set(
      produce((state: OnboardingSlice) => {
        state.onboardingType = onboardingType
      }),
    ),
  setOnboardingStep: (step) =>
    set(
      produce((state: OnboardingSlice) => {
        if (!step) {
          state.isOnboarding = false
          state.startPath = null
        }
        state.onboardingStep = step
      }),
    ),
  setStartPath: (path) =>
    set(
      produce((state: OnboardingSlice) => {
        state.startPath = path
      }),
    ),
  setApprovalRequestData: (data) =>
    set(
      produce((state: OnboardingSlice) => {
        state.approvalRequestData = data
      }),
    ),
  reset: () => set({ ...initialOnboardingState }),
})
