import React from 'react'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import styles from './index.module.css'
import cx from 'clsx'
const Tooltip: React.FC<React.ComponentProps<typeof ReactTooltip>> = ({
  children,
  className,
  variant = 'dark',
  classNameArrow = 'text-slate-950',
  delayShow = 333,
  id,
  ...props
}) => {
  return (
    <ReactTooltip
      delayShow={delayShow}
      id={id}
      classNameArrow={classNameArrow}
      variant={variant}
      className={cx(styles.tooltip, className)}
      {...props}
    >
      {children}
    </ReactTooltip>
  )
}

export default Tooltip
