import {
  CSSObjectWithLabel,
  ControlProps,
  GroupBase,
  OptionProps,
  StylesConfig,
  ValueContainerProps,
} from 'react-select'

export const getBorderColor = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  state: ControlProps<Option, IsMulti, Group>,
  errorMessage?: string,
) => {
  if (state.isDisabled) {
    return 'rgb(209 213 219)'
  } else if (errorMessage) {
    return 'rgb(252 165 165)'
  } else if (state.isFocused) {
    return 'rgb(59 130 246)'
  } else {
    return 'rgb(229 231 235)'
  }
}

export const getSelectStyles = <Option, IsMulti extends boolean, Group extends GroupBase<Option> = GroupBase<Option>>(
  errorMessage?: string,
  alignRight?: boolean,
): StylesConfig<Option, IsMulti, Group> => ({
  control: (base: CSSObjectWithLabel, state: ControlProps<Option, IsMulti, Group>) => ({
    ...base,
    padding: '0.5px 0',
    borderRadius: '8px',
    minHeight: '30px',
    backgroundColor: state.isDisabled ? 'rgb(249 250 251)' : 'white',
    borderColor: getBorderColor(state, errorMessage),
    color: state.isDisabled ? 'rgb(107 114 128)' : 'rgb(2 6 23)',
    transition: 'color ease-in-out, box-shadow 285ms',
    outline: 'none',
    ':hover': {
      borderColor: errorMessage ? 'rgb(252 165 165)' : state.isFocused ? 'rgb(59 130 246)' : 'rgb(229 231 235)',
      boxShadow: errorMessage
        ? '0 0 0 3px rgba(254, 228, 226, 1), 0 2px 1px 0 rgba(16, 24, 40, 0.05)'
        : '0 0 0 3px rgba(119, 173, 237, 0.24), 0 2px 1px 0 rgba(16, 24, 40, 0.05)',
    },
    boxShadow: '0 1px 2px 0 rgba(16, 24, 40, 0.05)',
    transitionTimingFunction: 'cubic-bezier(0.23, 1, 0.32, 1)',
  }),
  menuPortal: (base: CSSObjectWithLabel) => ({
    ...base,
    zIndex: 50,
  }),
  menu: (base: CSSObjectWithLabel) => ({
    ...base,
    borderRadius: '8px',
    overflow: 'hidden',
    ...(alignRight && { right: 0 }),
  }),
  input: (base: CSSObjectWithLabel) => ({
    ...base,
    paddingTop: '1px',
    paddingBottom: '1px',
  }),
  dropdownIndicator: (base: CSSObjectWithLabel) => ({
    ...base,
    padding: '6.5px 5.5px',
  }),
  clearIndicator: (base: CSSObjectWithLabel) => ({
    ...base,
    padding: '5px 8px',
  }),
  valueContainer: (base: CSSObjectWithLabel, props: ValueContainerProps<Option, IsMulti>) => ({
    ...base,
    padding: props.selectProps.isClearable ? '0 0 0 14px' : '0 12px 0 14px',
  }),
  multiValue: (base: CSSObjectWithLabel) => ({
    ...base,
    borderRadius: '8px',
  }),
  multiValueRemove: (base: CSSObjectWithLabel) => ({
    ...base,
    borderRadius: '8px',
  }),
  option: (base: CSSObjectWithLabel, props: OptionProps<Option, IsMulti>) => {
    let backgroundColor = 'white'
    let color = '#374151'
    if (props.isSelected) {
      backgroundColor = '#9DB9FF'
      color = 'white'
    }
    if (props.isFocused && !props.isSelected) {
      backgroundColor = '#DEEBFF'
    }
    return {
      ...base,
      padding: '4px 12px',
      backgroundColor,
      color,
    }
  },
  singleValue: (base: CSSObjectWithLabel) => ({
    ...base,
    color: '#343A40',
  }),
  indicatorSeparator: (base: CSSObjectWithLabel) => ({
    ...base,
    height: 'calc(100% - 12px)',
    borderRadius: '8px',
    marginTop: '6px',
    marginBottom: '6px',
    backgroundColor: '#D4D4D4',
    opacity: '.6',
  }),
})
