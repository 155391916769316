import { CryptoCurrenciesKeys } from '@queries/Keys'

import { CryptoCurrenciesResponse } from '@models/CryptoCurrencies'
import { API_ADDRESS, cryptoCurrencyOptions } from '@src/constants'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const PATH = '/api/cryptocurrencies'

export const useGetCryptoCurrencies = (enabled = true) => {
  return useQuery(
    [CryptoCurrenciesKeys.CryptoCurrencies],
    async () => {
      const response = await axios.get<CryptoCurrenciesResponse[]>(PATH, {
        baseURL: API_ADDRESS,
        headers: {
          'Content-Type': 'application/json',
        },
      })

      return response.data
    },
    {
      enabled,
      placeholderData: cryptoCurrencyOptions.map((option, index) => ({
        created_at: new Date().toISOString(),
        id: index,
        symbol: option.value,
      })),
    },
  )
}
