import i18n from '../i18n'

import { CompletionType, CommunicationType, FieldType, Frequency, StepFlow, Role } from '@type/common'
import { CustomApprover } from '@pages/Pipelines/Builder/formTypes'

export { CompletionType, CommunicationType, FieldType as APPROVAL_REQUEST_FIELDS }
export const CompletionTypeLabels = {
  [CompletionType.all]: i18n.t('common:approveRules.all'),
  [CompletionType.any]: i18n.t('common:approveRules.any'),
  [CompletionType.empty]: i18n.t('common:approveRules.empty'),
}

export const comletionTypesOptions = [
  {
    value: CompletionType.all,
    label: CompletionTypeLabels[CompletionType.all],
  },
  {
    value: CompletionType.any,
    label: CompletionTypeLabels[CompletionType.any],
  },
]

export const comletionTypesOptionsWithEmpty = [
  ...comletionTypesOptions,
  {
    value: CompletionType.empty,
    label: CompletionTypeLabels[CompletionType.empty],
  },
]

export const approvalStepTypes = {
  [StepFlow.org]: {
    label: i18n.t('builder:step.types.orgLabel'),
    value: StepFlow.org,
  },
  [StepFlow.user]: {
    label: i18n.t('builder:step.types.usersLabel'),
    value: StepFlow.user,
  },
}

export const FrequencyItems = {
  [Frequency.daily]: i18n.t('common:reminderFrequency.daily'),
}

export const CustomApprovers = {
  [CustomApprover.manager]: {
    name: i18n.t('common:customApprovers.managerLabel'),
    key: CustomApprover.manager,
  },
  [CustomApprover.managerOfManager]: {
    name: i18n.t('common:customApprovers.managerOfManagerLabel'),
    key: CustomApprover.managerOfManager,
  },
  [CustomApprover.requestor]: {
    name: i18n.t('common:customApprovers.requestorLabel'),
    key: CustomApprover.requestor,
  },
  [CustomApprover.specifiedByRequestor]: {
    name: i18n.t('common:customApprovers.empty'),
    key: CustomApprover.specifiedByRequestor,
  },
  [CustomApprover.costCenterOwner]: {
    name: i18n.t('common:customApprovers.costCenterOwnerLabel'),
    key: CustomApprover.costCenterOwner,
  },
}

export const RoleOptions = [
  {
    label: 'Admin',
    value: Role.admin,
    tip: 'Can create/edit workflows, invite/delete users, view request history',
  },
  {
    label: 'Member',
    value: Role.requestor,
    tip: 'Can approve and create requests, review requests involving them',
  },
]

export const popperModifiers = [
  {
    name: 'arrow',
    options: {
      padding: ({ popper }: { popper: { width: number } }) => ({
        right: popper.width - 64,
      }),
    },
  },
]
