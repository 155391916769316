import Cookies from 'js-cookie'
import { useLocation } from 'react-router-dom'

export const useGetUTMs = () => {
  const { search } = useLocation()
  const utms: Record<string, string> = {}

  const query = new URLSearchParams(search)
  Object.keys(Cookies.get()).forEach((key) => {
    if (key && key.startsWith('utm_')) {
      const value = Cookies.get(key)
      if (value) {
        utms[key] = value
      }
    }
  })
  query.forEach((value, key) => {
    if (key.startsWith('utm_')) {
      utms[key] = value
    }
  })

  return utms
}

export default useGetUTMs
