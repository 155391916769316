import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'

import en_PO from './en/PO.json'
import en_common from './en/common.json'
import en_builder from './en/builder.json'
import en_org from './en/org.json'
import en_billing from './en/billing.json'
import en_access from './en/access.json'
import en_request from './en/request.json'
import en_workflow from './en/workflow.json'
import en_settings from './en/settings.json'
import en_dashboard from './en/dashboard.json'
import en_users from './en/users.json'
import en_onboarding from './en/onboarding.json'
import en_mailbox from './en/mailbox.json'
import en_accounting from './en/accounting.json'
import en_pipelines from './en/pipelines.json'
import en_install_teams from './en/install-teams.json'
import en_portal from './en/portal.json'
import en_tasks from './en/tasks.json'
import en_team from './en/team.json'
import en_paywalls from './en/paywalls.json'
import en_reports from './en/reports.json'
import en_msteams from './en/msteams.json'
import en_invoice from './en/invoice.json'

// import ru_PO from './ru/PO.json'
// import ru_common from './ru/common.json'
// import ru_builder from './ru/builder.json'
// import ru_org from './ru/org.json'
// import ru_billing from './ru/billing.json'
// import ru_access from './ru/access.json'
// import ru_request from './ru/request.json'
// import ru_workflow from './ru/workflow.json'
// import ru_settings from './ru/settings.json'
// import ru_dashboard from './ru/dashboard.json'
// import ru_users from './ru/users.json'
// import ru_onboarding from './ru/onboarding.json'
// import ru_mailbox from './ru/mailbox.json'
// import ru_accounting from './ru/accounting.json'
// import ru_pipelines from './ru/pipelines.json'
// import ru_install_teams from './ru/install-teams.json'
// import ru_portal from './ru/portal.json'

import { Language } from '@src/types/common'

export const MapLangToI18N = {
  [Language.ENG]: 'en',
  // [Language.RUS]: 'ru',
}

export const defaultNS = 'common'
export const resources = {
  en: {
    PO: en_PO,
    common: en_common,
    builder: en_builder,
    org: en_org,
    billing: en_billing,
    access: en_access,
    request: en_request,
    workflow: en_workflow,
    settings: en_settings,
    dashboard: en_dashboard,
    users: en_users,
    onboarding: en_onboarding,
    mailbox: en_mailbox,
    accounting: en_accounting,
    pipelines: en_pipelines,
    install_teams: en_install_teams,
    vendor_portal: en_portal,
    tasks: en_tasks,
    team: en_team,
    paywalls: en_paywalls,
    reports: en_reports,
    msteams: en_msteams,
    invoice: en_invoice,
  },
  // ru: {
  //   PO: ru_PO,
  //   common: ru_common,
  //   builder: ru_builder,
  //   org: ru_org,
  //   billing: ru_billing,
  //   access: ru_access,
  //   request: ru_request,
  //   workflow: ru_workflow,
  //   settings: ru_settings,
  //   dashboard: ru_dashboard,
  //   users: ru_users,
  //   onboarding: ru_onboarding,
  //   mailbox: ru_mailbox,
  //   accounting: ru_accounting,
  //   pipelines: ru_pipelines,
  //   install_teams: ru_install_teams,
  //   portal: ru_portal,
  // },
} as const

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: MapLangToI18N[Language.ENG],
    ns: [
      'PO',
      'common',
      'builder',
      'org',
      'billing',
      'access',
      'request',
      'workflow',
      'settings',
      'dashboard',
      'users',
      'onboarding',
      'accounting',
      'pipelines',
      'install_teams',
      'paywalls',
    ],
    debug: true,
    defaultNS,
    resources,
    backend: {
      loadPath: './{{lng}}/{{ns}}.json',
    },
    interpolation: {
      escapeValue: false,
    },
  })

i18n.services.formatter?.add('capitalize', (value = '') => {
  return value[0]?.toUpperCase() + value.slice(1)
})

export default i18n
