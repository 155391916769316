import React, { useId } from 'react'
import cx from 'clsx'
import Tooltip from '@src/components/Tooltip'
import { twMerge } from 'tailwind-merge'

import styles from './index.module.css'

const enum Types {
  outlined = 'outlined',
  contained = 'contained',
  default = 'default',
}

const enum Colors {
  default = 'default',
  cancel = 'cancel',
  success = 'success',
  neutral = 'neutral',
  white = 'white',
  secondary = 'secondary',
  primary = 'primary',
  green = 'green',
  lavander = 'lavander',
  blue = 'blue',
  black = 'black',
}

const types = {
  [Types.outlined]: styles.outlined,
  [Types.contained]: styles.contained,
  [Types.default]: styles.default,
}

const colors = {
  [Colors.default]: '',
  [Colors.cancel]: styles.cancel,
  [Colors.success]: styles.success,
  [Colors.neutral]: styles.neutral,
  [Colors.primary]: styles.primary,
  [Colors.secondary]: styles.secondary,
  [Colors.white]: styles.white,
  [Colors.green]: styles.green,
  [Colors.lavander]: styles.lavander,
  [Colors.blue]: styles.blue,
  [Colors.black]: styles.black,
}

export interface ButtonProps {
  className?: string
  type?: 'outlined' | 'contained' | 'default'
  buttonType?: 'button' | 'submit' | 'reset' | undefined
  color?:
    | 'cancel'
    | 'success'
    | 'neutral'
    | 'white'
    | 'secondary'
    | 'primary'
    | 'green'
    | 'blue'
    | 'default'
    | 'black'
    | 'lavander'
  loading?: boolean
  disabled?: boolean
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  name?: string
  id?: string
  form?: string
  title?: string
  style?: React.CSSProperties
  tooltip?: string
}

export const Button: React.FC<React.PropsWithChildren<ButtonProps>> = ({
  children,
  className,
  type = Types.default,
  color = Colors.default,
  buttonType,
  loading = false,
  disabled,
  onClick,
  name,
  id,
  tooltip,
  ...props
}) => {
  const tooltipId = useId()

  const dataAttr =
    id || name
      ? {
          'data-qa': `${id || name}-button`,
        }
      : {}

  const ButtonComponent = (
    <button
      className={twMerge(
        cx(
          'flex min-w-[48px] items-center justify-center px-4 py-1',
          styles.button,
          types[type],
          colors[color],
          loading && 'relative',
          className,
        ),
      )}
      {...(buttonType ? { type: buttonType } : {})}
      disabled={disabled || loading}
      onClick={onClick}
      name={name}
      id={id}
      {...dataAttr}
      {...props}
    >
      <div className={cx('flex items-center justify-center gap-1', loading && 'text-transparent')}>{children}</div>
      {loading && <span className={cx('spinner-border absolute text-center')} role="status" aria-hidden="true" />}
    </button>
  )

  if (tooltip) {
    return (
      <span data-tooltip-id={tooltipId}>
        {ButtonComponent}
        {tooltip && (
          <Tooltip id={tooltipId}>
            <span>{tooltip}</span>
          </Tooltip>
        )}
      </span>
    )
  }

  return ButtonComponent
}

export default Button
